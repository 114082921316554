<template>
  <v-card :loading="isLoading">
    <v-card-text class="v-card-text">
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="İcralar"
            icon="mdi-card-multiple-outline"
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            :hide-edit="clusterId && !can('edit-enforcement')"
            :edit-enabled="selectedItems.length === 1"
            @click:edit="handleEditClick"
            :search.sync="search.query"
            v-bind="titleBarAttrs"
          >
            <template v-slot:buttons:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="float-end"
                    color="pink"
                    text
                    icon
                    small
                    @click.prevent="handleJSONDownloadclick"
                    :disabled="selectedItems.length === 0"
                    v-on="on"
                    v-bind="attrs"
                  >
                    <v-icon>mdi-code-json</v-icon>
                  </v-btn>
                </template>

                JSON olarak dışarı aktar
              </v-tooltip>

              <a
                download="icra-listesi.json"
                style="visibility: none"
                ref="downloadAnchor"
              ></a>
            </template>
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'enforcements.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.id="{ item }">
          <router-link
            :to="{
              name: 'enforcements.show',
              params: { id: item.id },
            }"
          >
            {{ item.id }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.resident="{ item, value }">
          <router-link
            v-if="can('see-house-income')"
            :to="{
              name: 'incomes.residents.show',
              params: { id: item.resident_id },
            }"
          >
            {{ value }}
          </router-link>
          <template v-else>{{ value }}</template>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.issued_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_closed="{ value }">
          <rs-table-cell-boolean
            :value="value"
            :title="closed_on ? closed_on.toLocaleDateString() : null"
          />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.collecting_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.left_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.expense_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.expense_refund_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.interest_amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.closed_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions } from "@/view/mixins";

export default {
  name: "InvoiceList",
  mixins: [filtersToURL, hasExport, hasPermissions],
  computed: {
    ...mapGetters(["clusterId", "companyId"]),
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
    companyId() {
      this.loadList();
    },
    clusterId() {
      this.loadList();
    },
  },
  data() {
    return {
      dataTableAttrs: {
        footerProps: {
          disableItemsPerPage: false,
        },
      },
      titleBarAttrs: {
        // exportColumns: {
        //   cluster: "TYA",
        //   provider: "Tedarikçi",
        //   billed_on: "Fatura Tarihi",
        // },
        exportUrl: "enforcements",
        exportParams: this.getParams,
      },
      search: {
        is_closed: false,
      },
      options: {
        sortBy: ["issued_on"],
        sortDesc: [true],
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => this.clusterId && !this.can("edit-enforcement"),
        },
        {
          text: "Takip No",
          value: "id",
          searchable: "text",
        },
        {
          text: "Dosya No",
          value: "enforcement_no",
          searchable: "text",
        },
        {
          text: "TYA",
          value: "cluster",
          searchable: false,
        },
        {
          text: "Avukat",
          value: "lawyer",
          searchable: "text",
        },
        {
          text: "İcra Edilen Kişi",
          value: "resident",
          searchable: "text",
          width: "150px",
        },
        {
          text: "İcra Tarihi",
          value: "issued_on",
          searchable: "date",
        },
        {
          text: "Kapanmış",
          value: "is_closed",
          searchable: "yesNo",
          align: "center",
        },
        {
          text: "İcra Dairesi",
          value: "enforcement_office",
          searchable: "text",
        },
        {
          text: "İcra Tutar",
          value: "amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Tahsilat Tutar",
          value: "collecting_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Kalan Tutar",
          value: "left_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Gider Tutar",
          value: "expense_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Gider İade Tutar",
          value: "expense_refund_amount",
          searchable: "number",
          align: "end",
          width: "120px",
        },
        {
          text: "Faiz Tutar",
          value: "interest_amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Kapanış Tarihi",
          value: "closed_on",
          searchable: "date",
        },
        {
          text: "İcraya Veren",
          value: "company_user",
          searchable: "text",
        },
      ],
    };
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      if (this.clusterId) {
        params.cluster_id = [this.clusterId];
      }

      return params;
    },
    loadList() {
      if (this.isLoading) {
        return;
      }

      this.resetTable();
      this.isLoading = true;

      const params = this.getParams();

      this.$api
        .query("enforcements", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleJSONDownloadclick() {
      if (this.selectedItems.length === 0 || this.isLoading) {
        return false;
      }

      this.isLoading = true;

      const params = { ids: this.selectedItems.map((i) => i.id) };

      this.$api
        .query("enforcements/request-form", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            var dataStr =
              "data:text/json;charset=utf-8," +
              encodeURIComponent(JSON.stringify(response.data.data));
            var anchor = this.$refs.downloadAnchor;
            anchor.setAttribute("href", dataStr);
            // dlAnchorElem.setAttribute("download", "scene.json");
            anchor.click();

            // var data =
            //   "text/json;charset=utf-8," +
            //   encodeURIComponent(JSON.stringify(response.data.data));

            // var a = document.createElement("a");
            // a.href = "data:" + data;
            // a.download = "data.json";
            // a.innerHTML = "download JSON";

            // var container = document.getElementsByClassName(".v-card-text");
            // container.appendChild(a);
            // a.click();
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
};
</script>
